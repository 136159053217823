import React from 'react';
import styled from 'styled-components';
import { Description, H1, H3 } from '@/components/Typography';
import ButtonDefault from '@/components/ButtonDefault';
import viewport1Img from '../../assets/img/orderAggregationVp1.png';
import viewport4Img from '../../assets/img/orderAggregationVp4.png';

import Section from '@/components/Section';
import FirstViewport from '@/components/FirstViewport';
import StepBlueCheckCard from '@/components/StepBlueCheckCard';
import ReversibleCardsComponent from '@/components/ReversibleCardsComponent';
import { OrderAggregationManagementPageContent } from '@/pages/order-aggregation-and-management';
import TickedItems from '@/components/TickedItems';

const FirstVpWrapper = styled.div`
  background: linear-gradient(118.47deg, #f8f9fa 3.57%, #e4e6f0 100%);
`;

const SecondViewport = styled(Section)`
  background: linear-gradient(#ffffff, #e7edf3);
  padding-bottom: 80px;
  @media (max-width: 800px) {
    padding-bottom: 1rem;
  }
`;

const DefaultViewport = styled(SecondViewport)<{
  withoutPaddingTop?: boolean;
  withoutPaddingBottom?: boolean;
}>`
  background: transparent;
  ${({ withoutPaddingTop }) => withoutPaddingTop && `padding-top: 0;`}
  ${({ withoutPaddingBottom }) => withoutPaddingBottom && `padding-bottom: 0;`}
`;

const StyledDescription = styled(Description)`
  :first-of-type {
    margin-top: 1rem;
  }
  color: #495b6c;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
`;

const ViewPortsWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const StepsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

const StyledButtonDefault = styled(ButtonDefault)`
  margin-top: 2rem;
`;

const AdditionalWrapper = styled.div`
  border-radius: 20px;
  background-color: #010100;
`;

const FourthViewPort = styled.div<{ image: string }>`
  ${({ image }) =>
    image &&
    `background-image: ${`url(${image})`};
    background-repeat: no-repeat;
    background-size: cover;`}
  border-radius: 20px;
  padding: 69px 40% 82px 130px;
  display: flex;
  flex-direction: column;
`;

const Button = styled(ButtonDefault)`
  padding: 15px 30px;
  width: calc(180px - 30px * 2);
  border: 1px solid #adb5bd;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  background-color: transparent;
  transition: 0.4s;
  &:hover {
    background-color: #dbe3eb4e;
  }
`;

interface ThisContainerProps {
  content: OrderAggregationManagementPageContent;
}

const OrderAggregationManagementContainer: React.FunctionComponent<
  ThisContainerProps
> = ({ content: { viewport1, viewport2, viewport3, viewport4 } }) => (
  <>
    <FirstVpWrapper>
      <FirstViewport img={viewport1Img}>
        <H1>
          {viewport1.title1}
          {` `}
          <span className="accent-text">{viewport1.title2}</span>
        </H1>
        <StyledDescription>{viewport1.description}</StyledDescription>
        <TickedItems textColor="#495B6C" items={viewport1.tickedDescription} />
        <StyledButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </StyledButtonDefault>
      </FirstViewport>
    </FirstVpWrapper>

    <SecondViewport>
      <H3 style={{ maxWidth: 768, margin: `0 auto`, textAlign: `center` }}>
        {viewport2.title}
      </H3>
      <StepsWrapper>
        {viewport2.carouselItems.map((item) => (
          <StepBlueCheckCard
            title={item.title}
            description={item.description}
          />
        ))}
      </StepsWrapper>
    </SecondViewport>

    <ViewPortsWrapper>
      <DefaultViewport withoutPaddingBottom>
        <ReversibleCardsComponent cards={viewport3.cards} />
      </DefaultViewport>

      <DefaultViewport withoutPaddingTop>
        <AdditionalWrapper>
          <FourthViewPort image={viewport4Img}>
            <H3
              style={{ marginBottom: `1.5rem`, maxWidth: 450, color: `#fff` }}
            >
              {viewport4.cardLabel}
            </H3>
            <Button to={viewport4.button.url}>{viewport4.button.label}</Button>
          </FourthViewPort>
        </AdditionalWrapper>
      </DefaultViewport>
    </ViewPortsWrapper>
  </>
);

export default OrderAggregationManagementContainer;
