import React from 'react';
import styled from 'styled-components';
import { P, Subtitle5 } from './Typography';
import BlueCircleCheckIcon from '../assets/icons/blueCircleCheck.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #c4dbf0;
  background: #fff;
  box-shadow: 0px 12px 30px 0px rgba(129, 129, 165, 0.2);
  height: calc(100% - 35px * 2);
  padding: 35px;
`;

const TopLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Title = styled(Subtitle5)`
  color: #13273f;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
`;

const Step = styled(P)`
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  color: #8181a5;
`;

const Description = styled(P)`
  color: #495b6c;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
`;

interface SolutionComponentProps {
  title: string;
  description: string;
  step?: number;
}
const StepBlueCheckCard: React.FunctionComponent<SolutionComponentProps> = ({
  title,
  description,
  step,
}) => (
  <Wrapper>
    <TopLine>
      <BlueCircleCheckIcon />
      {step && <Step>Step {step}</Step>}
    </TopLine>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Wrapper>
);

export default StepBlueCheckCard;
