import React from 'react';
import Main from '../containers/Layout';
import orderAggregationManagementContent from '../../content/pages/order-aggregation-and-management.yml';
import OrderAggregationManagementContainer from '@/containers/toolkit/OrderAggregationManagementContainer';

export interface OrderAggregationManagementPageContent {
  viewport1: {
    title1: string;
    title2: string;
    description: string;
    tickedDescription: { item: string }[];
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    title: string;
    carouselItems: {
      title: string;
      description: string;
    }[];
  };
  viewport3: {
    cards: {
      title: string;
      tickedDescription: { item: string }[];
      image: string;
    }[];
  };
  viewport4: {
    cardLabel: string;
    button: {
      label: string;
      url: string;
    };
  };
}

const OrderAggregationAndManagement: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3, viewport4 } =
    orderAggregationManagementContent as unknown as OrderAggregationManagementPageContent;
  return (
    <Main>
      <OrderAggregationManagementContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
        }}
      />
    </Main>
  );
};

export default OrderAggregationAndManagement;
